<template>
  <div class="me">
    <Header :active=3 :full='fullWidth'/>
    <!-- 轮播图 -->
    <el-image style="width: 100%; margin-top:74px" :src="imgs[0].url" v-if="fullWidth"></el-image>
    <el-image style="width: 100%; margin-top:52px" :src="imgs[1].url"  v-if="!fullWidth"></el-image>
    <!-- <el-carousel  :height="fullWidth == true ? '400px' : '150px'">
      <el-carousel-item v-for="item in imgs" :key="item.id" >
        <el-image :src="item.url" style="width: 100%;height:100%;"></el-image>
      </el-carousel-item>
    </el-carousel> -->
    <!-- PC端 -->
    <!--行业难题 -->
    <div class="me_center" v-show="fullWidth">
      <div class="center_top"> 
        <span class="me_span">Business introduction</span>
        <span class="span_centet">行业难题</span>
        <span class="span_botton"></span>
      </div>
      <div class="solu">
        <div class="solu_left">
          <img class="solu_img" src="../assets/img/solution/anquan.png" alt="">
          <span class="solu_title">01 安全问题</span>
          <span class="solu_text">产品假货横行，假酒危害消费者生命财产安全与企业品牌。</span>
        </div>
        <div class="solu_center">
          
          <span class="solu_title">02 扰乱市场</span>
          <span class="solu_text">经销商通过各种方式破坏溯源信息，恶意窜货，扰乱市场价格稳定。</span>
          <img class="solu_img" src="../assets/img/solution/raoluan.png" alt="">
        </div>
        <div class="solu_left">
          <img class="solu_img" src="../assets/img/solution/guocheng.png" alt="">
          <span class="solu_title">03 过程繁琐</span>
          <span class="solu_text">企业产品包装与工厂、仓库个性化，产线速度快，发货量大，方案难度高。</span>
        </div>
      </div>  
    </div>
    <!-- 解决方案 -->
    <div class="scheme" v-show="fullWidth">
      <div class="center_top"> 
        <span class="me_span">Solution</span>
        <span class="span_centet">解决方案</span>
        <span class="span_botton"></span>
      </div>
      <div class="scheme_left">
        <img class="scheme_img" src="../assets/img/solution/jiejue.png" alt="">
        <ul class="scheme_span">
          <li>通过流水线单瓶赋码，⻝物单独赋码，追溯产品质量量和产品流向信息，并帮助企业建⽴⼤数据市场分析数据库，为企业把握市场动向提供科学化可靠依据。</li>
          <li>为酒类防伪监管平台提供指定格式的真实可靠的产品防伪追溯数据，同时对企业产品⽣生产管理理实现信息化，做到企业防窜货，提升企业产品安全与质量量，建⽴企业内部产品安全检查机制，为企业提供产品的追溯链条信息及各个节点的实际销售，库存物流等服务。</li>
          <li>通过⽕眼电商导流系统和数字营销系统，吸引⽤用户流量，发展食品酒⽔行业电商平台，助力企业营销服务，实现⽤用户分析和客户管理理，有助于加强企业品牌效应和企业竞争力。</li>
        </ul>
      </div>
      
    </div>

    <!-- 移动端样式 -->
    <div class="move_center" v-show="!fullWidth">
      <div class="movecenter_top"> 
        <span class="moveme_span">Business introduction</span>
        <span class="movespan_centet">行业难题</span>
        <span class="movespan_botton"></span>
      </div>
     <!-- 安全问题 -->
      <div class="movesolu">
        <div class="move_top">
          <img class="movesolu_img" src="../assets/img/solution/anquan.png" alt="">
          <div class="move_right">
            <span class="movesolu_title">01 安全问题</span>
            <span class="movesolu_text">产品假货横行，假酒危害消费者生命财产安全与企业品牌。</span>
          </div>
        </div>
        <div class="movesolu_center">
          <div class="move_right">
            <span class="movesolu_title">02 扰乱市场</span>
            <span class="movesolu_text">经销商通过各种方式破坏溯源信息，恶意窜货，扰乱市场价格稳定。</span>
          </div>
           <img class="movesolu_img" src="../assets/img/solution/raoluan.png" alt="">
        </div>
        <div class="move_top">
          <img class="movesolu_img" src="../assets/img/solution/guocheng.png" alt="">
          <div class="move_right">
            <span class="movesolu_title">03 过程繁琐</span>
            <span class="movesolu_text">企业产品包装与工厂、仓库个性化，产线速度快，发货量大，方案难度高。</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="movescheme" v-show="!fullWidth">
      <div class="movecenter_top"> 
        <span class="moveme_span">Solution</span>
        <span class="movespan_centet">解决方案</span>
        <span class="movespan_botton"></span>
      </div>
      <div class="movescheme_left">
        <img class="movescheme_img" src="../assets/img/solution/jiejue.png" alt="">
        <span class="movescheme_span">
          结合包装提供高隐秘，高强度，高防伪一物一码方案，避免信息被破坏，造假。 
          通过物流追溯系统记录产品多级经销发货信息，市场稽查清晰简便。 
          通过多种营销方式提高用户参与率，实现市场假货窜货预警，获取市场大数据。
        </span>
      </div>
    </div>
    <Footer :full='fullWidth'/>
  </div>
  
</template>

<script>
// @ is an alias to /src

import Header from "@/components/Header.vue";
import Footer from '@/components/Footer.vue'
export default {
  name: "Home",
  components: {
      Header,
      Footer
    },
 
  data(){
    return{
      // 轮播图
      imgs:[
        {id:1,url:require('../assets/img/banner/banner.png')},
        {id:1,url:require('../assets/img/banner/move_banner.png')},
      ],
      fullWidth:true,
    }
  },
  created(){
    
  },
  methods:{
      page_width() {//获取屏幕宽度
      let screenWidth = window.screen.width;
      console.log(screenWidth);
      if (screenWidth < 800) {
        this.fullWidth = false;
      } else {
        this.fullWidth = true;
      }
    
    },
    setRem() {
      var whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
      var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
      var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
    }
  },
  mounted(){
    window.onresize = () => {//监听屏幕变化
      this.page_width();
      this.setRem()
    };
    this.page_width();
    this.setRem()
  },

};
</script>
<style scoped lang="scss">
.me{
  
}
/deep/.el-image__inner{ vertical-align: bottom;}
/* PC端样式 */
/* 头 */
.me_center{display: flex;flex-direction:column;width: 1024px;margin: 60px auto 0;}
.center_top{margin: 0 auto;display: flex;flex-direction:column;align-items: center;}
.me_span{/* width: 377px; */ height: 25px;font-size: 18px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #E02222;line-height: 25px;}
.span_centet{height: 25px;font-size: 18px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #474747;line-height: 25px;margin: 11px 0 10px 0;}
.span_botton{width: 79px;height: 8px;background: #DDDDDD;border-radius: 4px;}

.solu{display: flex; justify-content: space-between;margin-top: 37px;height: 600px;padding: 0 0 89px 0; background: #FFFFFF;}
.solu_left{display: flex;flex-direction: column;border-bottom: #E02222 2px solid; margin-left: 16px;}
.solu_left .solu_img{width: 331px;height: 303px;}
.solu_title{width: 100px;height: 25px;font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #E02222;line-height: 25px;margin-top: 30px;}
.solu_text{ width: 331px;height: 60px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #595959;line-height: 30px;margin-top: 13px;}
.solu_center{display: flex;flex-direction: column;border-top: #E02222 2px solid; margin-left: 16px;}
.solu_center .solu_text{margin-bottom: 78px;}
/* 解决方案 */
.scheme{display: flex;flex-direction:column;background: #F8F8F8;padding: 50px 0 0 0;margin-top: 39px; padding-bottom: 108px;}
.scheme_left{display: flex;background: #E02222;width: 1024px;margin:39px auto 0; }
.scheme_span{margin: 39px 0 108px 38px ; padding: 118px 56px 83px 38px; width: 418px;height: 378px;font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 21px;letter-spacing: 2px;}
.scheme_img{width: 512px;height: 579px;}
/* 移动端样式 */
/* 头 */
.move_center{padding-top: 20px;display: flex;flex-direction:column;background: #F8F8F8;}
.movecenter_top{margin: 0 auto;display: flex;flex-direction:column;align-items: center;}
.moveme_span{height: 17px;font-size: 12px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #E02222;line-height: 17px;}
.movespan_centet{margin-top: 5px;height: 17px;font-size: 12px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #474747;line-height: 17px;}
.movespan_botton{width: 40px;height: 4px;background: #DDDDDD;border-radius: 4px;margin-top: 5px;}
/* 行业难题 */
.movesolu{display: flex;flex-direction: column; padding: 15px;}
.move_top{display: flex;border-right: #E02222 2px solid;margin-bottom: 10px;}
.movesolu_img{width: 197px;height: 180px;}
.movesolu_title{height: 17px;font-size: 12px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #E02222;line-height: 17px;}
.movesolu_text{width: 123px;height: 90px;font-size: 10px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #595959;line-height: 30px;}
.move_right{display: flex;flex-direction: column;justify-content: center;align-items: center;margin-left: 15px;}
.movesolu_center{display: flex;border-left: #E02222 2px solid;margin-bottom: 10px;justify-content: space-between;}
/* 解决方案 */
.movescheme{padding-top:15px ;display: flex;flex-direction:column;}
.movescheme_left{display: flex;background: #E02222;width: 275px;justify-content: center;margin: 0 auto;margin-top: 15px;margin-bottom: 46px;}
.movescheme_img{width: 148px;height: 169px;}
.movescheme_span{padding: 30px 12px 23px 11px; width: 127px;height: 116px;font-size: 6px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 19px;}
</style>